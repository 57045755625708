<template>
  <b-container class="mt-4">
    <b-form @submit="onSubmit">
      <b-card header-tag="header" header-class="header">
        <template #header>
          <label v-text="title" class="mr-2 text-white"></label>
          <b-button variant="primary" type="submit">Gönder</b-button>
        </template>
        <b-alert class="spacing" show>
          Bilgilerinizi girin ve <b>Gönder</b> butonuna tıklayın.
        </b-alert>
        <b-row>
          <b-col>
            <InputTile
              label="Tc Kimlik No"
              v-model="fedFormItems.ssn"
              :required="true"
              type="number"
            />
            <InputTile
              label="Adı"
              v-model="fedFormItems.name"
              :required="true"
              type="text"
            />
            <InputTile
              label="E-Posta Adresi"
              v-model="fedFormItems.email"
              :required="true"
              type="email"
            />
            <InputTile
              label="Şifre"
              v-model="fedFormItems.password"
              :required="true"
              type="text"
            />
          </b-col>
          <b-col>
            <InputTile
              label="Başkan"
              v-model="fedFormItems.minister"
              :required="true"
              type="text"
            />
            <InputTile
              label="Genel Sekreter"
              v-model="fedFormItems.generalSecretary"
              :required="true"
              type="text"
            />
            <InputTile label="Ödeme Bilgisi" :custom="true">
              <b-textarea
                v-model="fedFormItems.paymentInformation"
                rows="3"
                max-rows="3"
              ></b-textarea>
            </InputTile>
          </b-col>
        </b-row>

        <b-container class="mt-4">
          <h4 class="mt-4">Kayan Yazı</h4>
          <ckeditor
            :editor="editor"
            v-model="fedFormItems.scrollingText"
            :config="editorConfig"
          ></ckeditor>
        </b-container>
        <b-container class="mt-4">
          <h4 class="mt-4">Sıkça Sorulan Sorular - Federasyon</h4>
          <ckeditor
            :editor="editor"
            v-model="fedFormItems.questionsFederation"
            :config="editorConfig"
          ></ckeditor>
        </b-container>
        <b-container class="mt-4">
          <h4 class="mt-4">Sıkça Sorulan Sorular - Personel</h4>
          <ckeditor
            :editor="editor"
            v-model="fedFormItems.questionsPersonal"
            :config="editorConfig"
          ></ckeditor>
        </b-container>
        <b-container class="mt-4">
          <h4 class="mt-4">Açılır Pencere</h4>
          <b-form-file
            v-model="selectedFile"
            :placeholder="selectedFileName"
            drop-placeholder="Buraya bırakın..."
            browse-text="Dosya Seç"
          ></b-form-file>
          <b-img :src="getPopupImage" fluid class="mt-4"></b-img>
        </b-container>
      </b-card>
    </b-form>
  </b-container>
</template>

<script>
import { mapGetters } from "vuex";
import InputTile from "./common/InputTile.vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import "@ckeditor/ckeditor5-build-classic/build/translations/tr";
export default {
  components: { InputTile },
  name: "Settings",
  data() {
    return {
      editor: ClassicEditor,
      selectedImageFile: null,
      selectedFileName: "Dosya seçin veya buraya sürükleyin...",
      editorConfig: {
        language: "tr",
        toolbar: [
          "heading",
          "|",
          "bold",
          "italic",
          "link",
          "bulletedList",
          "numberedList",
          "|",
          "insertTable",
          "|",
          "undo",
          "redo",
        ],
      },
    };
  },
  computed: {
    ...mapGetters(["getFederationItems", "getPopupImage"]),
    fedFormItems: {
      get() {
        return this.getFederationItems;
      },
    },
    selectedFile: {
      get() {
        return this.selectedImageFile;
      },
      set(value) {
        this.selectedImageFile = value;
        const reader = new FileReader();
        reader.readAsDataURL(value);
        reader.onloadend = () => {
          this.$store.commit("updatePopupImage", reader.result);
        };
      },
    },
  },
  props: ["title", "submit"],
  beforeUpdate() {
    if (this.fedFormItems.popupWindow) this.selectedFileName = "Hazırda Var";
  },
  created() {
    this.$store.dispatch("initFederation");
    this.$store.dispatch("initPopupImage");
  },
  methods: {
    onSubmit(event) {
      event.preventDefault();
      if (this.selectedFile) {
        this.fedFormItems.changepopupWindow = this.fedFormItems.popupWindow;
        this.fedFormItems.popupWindow = this.selectedFile;
      }
      this.$store.dispatch("editFederation", this.fedFormItems);
    },
  },
};
</script>

<style scoped>
.header {
  background: rgb(108, 117, 125);
  background: linear-gradient(
    180deg,
    rgb(108, 117, 125) 10%,
    rgba(53, 57, 61, 1) 85%
  );
}
.buttonBar {
  text-align: end;
}
.center {
  text-align: center;
}
.labelContainer {
  display: grid;
  place-items: left;
}
.spacing {
  margin-top: 0.5rem;
}
</style>