<template>
  <FedForm v-bind="props" />
</template>

<script>
import FedForm from "@/components/forms/FedForm.vue";
export default {
  components: { FedForm },
  data() {
    return {
      props: {
        title: "Federasyon Güncelle",
      },
    };
  },
};
</script>

<style scoped>
</style>